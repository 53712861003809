import styled from 'styled-components'
import { ButtonMedium } from '../button/styled'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.palette.gray10};
  width: 100%;
  min-height: 250px;
  text-align: center;
  display: grid;
  justify-content: center;
  align-content: center;

  h3 {
    margin-bottom: 16px;
  }

  ${ButtonMedium} {
    padding-left: 62px;
    padding-right: 62px;
  }
`
