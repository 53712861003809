import styled from 'styled-components'

export const NavSection = styled.section`
  background-color: ${({ theme }) => theme.palette.blue80};
  color: ${({ theme }) => theme.palette.white};
  max-width: 1280px;
  margin: -70px auto 130px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-template-columns: auto;

    section {
      border-right: none;
      border-bottom: 1px solid ${({ theme }) => theme.palette.blue70};
    }
  }

  section {
    padding: 36px;
    border-right: 1px solid ${({ theme }) => theme.palette.blue70};
    display: grid;
    grid-template-rows: auto 1fr auto;
    align-items: start;

    :last-child {
      border: none;
    }
  }

  a {
    color: ${({ theme }) => theme.palette.blue40};
  }

  a:hover {
    color: ${({ theme }) => theme.palette.white};
  }

  p {
    margin-bottom: 36px;
    margin-top: 5px;
  }
`
