import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Container } from './styled'

const PartnersCarouselSection: React.FC = (props) => {
  return (
    <Container>
      <Swiper
        spaceBetween={44}
        slidesPerView="auto"
        loop={true}
        loopedSlides={React.Children.count(props.children)}
      >
        {React.Children.map(props.children, (children) => (
          <SwiperSlide>{children}</SwiperSlide>
        ))}
      </Swiper>
    </Container>
  )
}

export default PartnersCarouselSection
