import styled from 'styled-components'
import { Caption } from '../../styles/shared'

export const Container = styled.div`
  .swiper {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;

    margin-bottom: 130px;
  }

  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
  }

  .swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform;

    width: 252px;
    min-height: 430px;
    border: solid 1px #eaeaee;

    padding: 8px;

    img ~ * {
      padding: 0 8px;
      margin-top: 0;
    }

    img {
      display: block;
      background-color: ${({ theme }) => theme.palette.gray20};
      width: 100%;
      margin-bottom: 16px;
      object-fit: cover;
    }

    span {
      ${Caption}
      color: ${({ theme }) => theme.palette.gray70}
    }
  }
`
