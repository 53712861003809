import styled from 'styled-components'
import { DisplaySmall } from '../../styles/shared'

export const PartnerHeaderSection = styled.section`
  text-align: center;
  max-width: 620px;
  margin: 0 auto 50px;
  padding: 0px 20px;

  p {
    ${DisplaySmall}
  }
`
