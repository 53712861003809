import React from 'react'
import { createGlobalStyle } from 'styled-components'
import NavSection from '../components/nav-section'
import BannerSection from '../components/banner-section'
import PartnerHeaderSection from '../components/partners-header-section'
import PartnersCarouselSection from '../components/partners-carousel-section'
import GettingStartedSection from '../components/getting-started-section'
import Button from '../components/button'
import ArrowLink from '../components/arrow-link'
import SEO from '../components/seo'
import Image from '../components/image'

const GlobalStyles = createGlobalStyle`
  html, body {
    background-color: ${({ theme }) => theme.palette.white};
  }
`

const IndexPage = () => {
  return (
    <>
      <GlobalStyles />
      <SEO title="Build the Future of Retail" />
      <BannerSection>
        <section>
          <h1>Build the Future of Retail</h1>
          <p>
            Integration guides and API documentation for easy integration with
            Sitoo
          </p>
        </section>
      </BannerSection>
      <NavSection>
        <section>
          <h3>Getting Started</h3>
          <p>
            Check out our introduction and learn the basics of the Sitoo REST
            API.
          </p>
          <ArrowLink to="/introduction/#overview">View Introduction</ArrowLink>
        </section>
        <section>
          <h3>Guides</h3>
          <p>
            Visit our Guides and have a look at section-by-section examples.
          </p>
          <ArrowLink to="/guides/overview">View Guides</ArrowLink>
        </section>
        <section>
          <h3>API&apos;s</h3>
          <p>
            View reference documentation of our APIs to learn about the
            resources available.
          </p>
          <ArrowLink to="/api-reference/overview">View API</ArrowLink>
        </section>
      </NavSection>
      <PartnerHeaderSection>
        <h3>Sitoo Partners</h3>
        <p>
          Pre-built integrations to market leading third party eCommerce, ERP,
          CRM and payment providers will help you grow your retail business.
        </p>
      </PartnerHeaderSection>
      <PartnersCarouselSection>
        <div>
          <Image src="partners/adyen.png" alt="adyen" />
          <span>Payment</span>
          <h5>Adyen</h5>
          <p>
            A single payments platform globally to accept payments and grow
            revenue online, on mobile, and at the point of sale.
          </p>
        </div>
        <div>
          <Image src="partners/magento.png" alt="magento" />
          <span>ECOMMERCE</span>
          <h5>Magento</h5>
          <p>
            Create engaging, shoppable experiences with Magento Commerce - the
            next-generation solutions to breathe life into your business.
          </p>
        </div>
        <div>
          <Image src="partners/salesforce.png" alt="salesforce" />
          <span>ERP/BI/PIM</span>
          <h5>Salesforce</h5>
          <p>
            A customer relationship management solution, bringing companies and
            customers together via one integrated CRM platform.
          </p>
        </div>
        <div>
          <Image src="partners/voyado.png" alt="voyado" />
          <span>CRM / Loyalty / Personalization</span>
          <h5>Voyado</h5>
          <p>
            Voyado is a cloud based Marketing Automation and Loyalty Platform
            developed for global retailers.
          </p>
        </div>
        <div>
          <Image src="partners/givex.png" alt="givex" />
          <span>Payment</span>
          <h5>Givex</h5>
          <p>
            Givex - the global industry leader and all-in-one gift card
            solution.
          </p>
        </div>
        <div>
          <Image src="partners/klarna.png" alt="klarna" />
          <span>Payment</span>
          <h5>Klarna</h5>
          <p>
            Klarna offers direct payments, pay after delivery options and
            installment plans in a smooth one-click purchase experience.
          </p>
        </div>
        <div>
          <Image src="partners/trimit.png" alt="trimit" />
          <span>ERP/BI/PIM</span>
          <h5>Trimit</h5>
          <p>
            TRIMIT adds industry-specific capability to Microsoft Dynamics
            NAV/Business Central by extending and improving existing
            functionality.
          </p>
        </div>
        <div>
          <Image src="partners/microsoft.png" alt="microsoft" />
          <span>ERP/BI/PIM</span>
          <h5>Microsoft</h5>
          <p>
            Microsoft provides a line of enterprise resource planning and
            customer relationship management software applications.
          </p>
        </div>
      </PartnersCarouselSection>
      <GettingStartedSection>
        <h3>Stay Connected</h3>
        <p>Sign up to receive Sitoo API updates.</p>
        <Button to="/sign-up" variant="green" size="big">
          Sign Up
        </Button>
      </GettingStartedSection>
    </>
  )
}

export default IndexPage
